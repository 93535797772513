import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import BCHomeForm from 'components/Tools/BusinessCalculator/HomeForm';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import BookmarkContext from 'utils/BookmarkContext';
import { getSetCookies } from 'utils/cookieUtility';
import { splashCheck } from 'utils/urlHelper';
import { createToolsBreadcrumbs } from '../utils/createToolsBreadcrumbs';

const BusinessCalcPage = (props) => {
  const title = 'Business Valuation Calculator';
  const data = props.data.contentfulTool;
  const breadcrumbs = createToolsBreadcrumbs(title);

  const [values, setValues] = useState({
    industry: '',
    otherIndustry: '',
    state: '',
  });

  const convertParams = (formValues) => {
    return `industry=${formValues.industry}&otherIndustry=${formValues.otherIndustry}&state=${formValues.state}`;
  };

  const onClickNext = (formValues) => {
    navigate(splashCheck(`/${props.pageContext.pagePrefix}/revenue?`) + convertParams(formValues));
  };

  useEffect(() => {
    const tempValues = getSetCookies(values);
    setValues(tempValues);
  }, []);

  const bookmarkData = {
    title: data.internalName,
    image: data?.cardImage?.image,
    subTitle: 'Tool',
    url: props.path,
    type: 'tool',
  };

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
        <Hero data={data.hero} />
        <BCHomeForm onClickNext={onClickNext} formValues={values} />
        {data.description && <DescriptionBlock data={data.description} />}
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default BusinessCalcPage;

export const BusinessCalcPageQuery = graphql`
  query BusinessCalcPageQuery($internalName: String!) {
    contentfulTool(internalName: { eq: $internalName }) {
      ...Tool
    }
  }
`;
