import { graphql, useStaticQuery } from 'gatsby';

const useBusinessCalcImages = () => {
  const { allContentfulImage } = useStaticQuery(graphql`
    query {
      allContentfulImage(
        filter: {
          contentful_id: {
            in: [
              "445dPs9dwaeSdK29zfjIoh" #BusinessCalc Calculating
              "4dXrbKaqkPmvknOijCJZSK" #BusinessCalc Result
              "1IGbxDMHZR6CPGSqexiqf4" #Business Calculator Form Home
            ]
          }
        }
      ) {
        edges {
          node {
            internalName
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  return allContentfulImage?.edges;
};

export default useBusinessCalcImages;
