import styled from 'styled-components';
import Modal from 'components/Core/Modal';

export const BusinessCalcFormContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 62px;
  background: var(--white);
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);
  margin: 0 auto 100px;

  .business-calc-home-form--container {
    display: flex;

    .business-calc-home-form--left-side {
      flex: auto;

      .business-calc-home-form--form {
        margin: 0 auto;

        .business-calc-home-form--form-item {
          margin-bottom: 35px;

          .business-calc-home-form--other-industry {
            margin-top: 15px;
          }
        }

        .business-calc-home-form--footer-description {
          max-width: 466px;
          font-weight: var(--font-weight-500);
          font-size: var(--font-size-13);
          line-height: var(--line-height-150);
          text-align: center;
          color: var(--grey-6);
          margin: 0 auto;
        }
      }
    }

    .business-calc-home-form--right-side {
      width: 348px;
      padding: 0 30px 0 80px;
      display: flex;
      align-items: center;

      .business-calc-home-form--man-image {
        max-width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 30px 24px;

    .business-calc-home-form--container {
      .business-calc-home-form--left-side {
        .business-calc-home-form--form {
          .business-calc-home-form--form-item {
            margin-bottom: 24px;
          }

          .business-calc-home-form--submit-button {
            line-height: 31px;
            padding: 12px 24px;
          }
        }
      }

      .business-calc-home-form--right-side {
        display: none;
      }
    }
  }

  @media (max-width: 575px) {
    padding: 20px 24px;
  }
`;

export const BCSteps = styled.div`
  text-align: center;
  max-width: 900px;
  margin: 60px auto 0;
  padding: 0 28px;

  .bc-step--field-question-control {
    margin-bottom: 0;
  }

  .bc-step--field-question {
    margin-bottom: 10px;

    .form-field--label-container {
      justify-content: center;
    }

    .form-field--input-container {
      margin: 10px auto 30px;
    }
  }

  .bc-step--field-description {
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-height-150);
    text-align: center;
    color: var(--dark);
    max-width: 627px;
    margin: 0 auto;

    &.calc-description {
      font-size: var(--font-size-25);
      line-height: var(--line-height-120);
      color: var(--darkest);
    }
  }

  .bc-step--field-text {
    max-width: 486px;
    margin: 30px auto;

    .text-field-helper {
      display: none !important;
    }
  }

  .bc-step--field-select {
    max-width: 486px;
  }

  .bc-step--field-img {
    &.calc-gif {
      width: 307px;
      max-width: calc(100% - 40px);
      margin-bottom: 20px;
      margin-top: -30px;
    }
  }

  .bc-step--button-group {
    margin-top: 10px;

    button {
      margin: 0 20px;
      min-width: 134px;
      display: inline-block;
    }

    .bc-step--button-back {
      color: var(--grey-8);
    }
  }

  @media (max-width: 575px) {
    margin-top: 44px;
    padding: 0 20px;

    .bc-step--button-group {
      button {
        margin: 0 10px;
      }
    }
  }
`;

export const ResultModal = styled(Modal)`
  .calc-result-modal {
    background: white;
    border-radius: 2px;
    text-align: center;
    padding: 40px;
  }

  .bc-step--field-description {
    text-align: center;
    margin: 0 auto;

    &.calc-result-title {
      text-align: center;
      margin-bottom: 36px;
    }

    &.calc-result-description {
      color: var(--darkest);
    }
  }

  .bc-step--field-text {
    margin: 20px auto;

    .text-field-helper {
      display: none !important;
    }
  }

  .bc-step--field-img {
    &.calc-result-img {
      margin-bottom: 27px;
      width: 207px;
      max-width: 100%;
    }
  }

  .bc-step--button-next {
    width: 100%;
  }
`;

export const ResultHeroContainer = styled.div`
  background: white;
  padding-bottom: ${(props) => props?.existingUser === 'No' && '50px'};

  .section-bc-results-hero--wrapper {
    background: ${(props) => 'url(' + props.backgroundImg + ')'};
    background-repeat: no-repeat;
  }

  .social-sharing-container {
    display: flex !important;
  }

  h2 {
    margin: 0;
  }

  .section-bc-results-hero--container {
    max-width: 1252px;
    margin: 0 auto;
    padding: 60px 28px 0;

    .section-bc-results-hero--first-section {
      display: flex;

      .section-bc-results-hero--first-left-side {
        flex: auto;
        padding: 0 38px 0 28px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .section-bc-results-hero--title {
          font-weight: normal;
          font-size: var(--font-size-28);
          line-height: var(--line-height-150);
          text-align: center;
          letter-spacing: var(--letter-spacing--0-01);
        }

        .section-bc-results-hero--estimate {
          font-size: var(--font-size-44);
          font-weight: var(--font-weight-900);
          letter-spacing: var(--letter-spacing--0-04);
          line-height: var(--line-height-110);
          margin-top: 25px;
          text-align: center;
        }

        .section-bc-results-hero--graph-container {
          width: 100%;
          max-width: 578px;
          margin: 8px auto 50px;
          position: relative;

          .section-bc-results-hero--graph-vertical-line {
            height: 50px;
            width: 2px;
            border-left: 2px dotted var(--darkest);
            margin: 0 auto;
          }

          .section-bc-results-hero--graph {
            width: 100%;
            height: 68px;
            position: relative;

            &:before {
              content: ' ';
              width: calc(100% - 24px);
              height: calc(100% - 30px);
              border-left: 2px dotted var(--darkest);
              border-right: 2px dotted var(--darkest);
              position: absolute;
              left: 12px;
              top: 30px;
              z-index: 1;
            }

            &:after {
              content: ' ';
              width: 70%;
              height: 100%;
              border-left: 2px dotted var(--darkest);
              border-right: 2px dotted var(--darkest);
              border-top: 2px dotted var(--darkest);
              position: absolute;
              left: 15%;
              top: 0%;
            }

            .section-bc-results-hero--graph-label {
              position: absolute;
              display: flex;
              justify-content: space-between;
              width: calc(100% + 30px);
              margin-top: -10px;
              margin-left: -15px;

              p {
                font-weight: var(--font-weight-500);
                font-size: var(--font-size-16);
                line-height: var(--line-height-150);
                color: var(--darkest);
              }
            }

            .section-bc-results-hero--main-graph {
              width: 100%;
              height: 24px;
              border-radius: 24px;
              background: var(--grey-3);
              position: relative;
              top: 30px;

              .section-bc-results-hero--main-graph-main {
                margin: 0 auto;
                width: 70%;
                height: 100%;
                border-radius: 0;
                background: var(--titan-blue-3);
              }
            }
          }

          .section-bc-results-hero--graph-label {
            position: absolute;
            display: flex;
            justify-content: space-between;
            width: calc(100% + 30px);
            margin-top: 6px;
            margin-left: -15px;

            p {
              font-weight: var(--font-weight-700);
              font-size: var(--font-size-20);
              line-height: var(--line-height-120);
              letter-spacing: var(--letter-spacing--0-01);
              color: var(--darkest);
            }
          }
        }
        .section-bc-results-hero-disclaimer {
          max-width: 400px;
          text-align: center;
          p {
            font-size: var(--font-size-13);
          }
        }
      }

      .section-bc-results-hero--first-right-side {
        .section-bc-results-hero--metrics {
          display: flex;
          justify-content: space-between;
          gap: 0 20px;

          .section-bc-results-hero--metrics-title {
            font-weight: var(--font-weight-700);
            font-size: var(--font-size-20);
            line-height: var(--line-height-120);
            letter-spacing: var(--letter-spacing--0-01);
          }

          .section-bc-results-hero--metrics-retake {
            font-weight: var(--font-weight-700);
            font-size: var(--font-size-20);
            line-height: var(--line-height-110);
            letter-spacing: var(--letter-spacing--0-01);
            margin: 0;

            svg {
              margin-right: 10px;
              font-size: var(--font-size-16);
            }
          }
        }

        .section-bc-results-hero--metrics-card-container {
          margin-top: 26px;

          .section-bc-results-hero--metrics-card {
            display: flex;
            align-items: center;
            position: relative;
            height: 100px;
            padding: 0 47px;
            box-shadow: 0px 3px 10px rgba(11, 26, 33, 0.08);
            margin: 20px 0;
            background: white;
            cursor: pointer;
            transition: box-shadow 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);

            .section-bc-results-hero--metrics-card-title {
              font-weight: var(--font-weight-700);
              font-size: var(--font-size-20);
              line-height: var(--line-height-120);
              letter-spacing: var(--letter-spacing--0-01);
              min-width: 108px;
              margin: 0 32px 0 0;
            }

            .section-bc-results-hero--metrics-card-value {
              font-weight: var(--font-weight-900);
              font-size: var(--font-size-28);
              line-height: var(--line-height-110);
              letter-spacing: var(--letter-spacing--0-03);
              word-wrap: break-word;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &:before {
              content: ' ';
              width: 10px;
              height: 100%;
              position: absolute;
              left: 0;
            }

            &:hover {
              box-shadow: 0px 15px 40px rgba(11, 26, 33, 0.1);
            }
          }
        }
      }
    }

    .section-bc-results-hero--second-section {
      display: flex;
      background: transparent;
      align-items: center;
      justify-content: center;
      padding: 76px 39px 60px;

      .section-bc-results-hero--share-title {
        font-weight: var(--font-weight-900);
        font-size: var(--font-size-25);
        line-height: var(--line-height-120);
        text-align: right;
        letter-spacing: var(--letter-spacing--0-02);
        color: var(--dark);
        margin-right: 24px;
        min-width: 197px;
      }

      .section-bc-results-hero--share-container {
        height: 100px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        .section-bc-results-hero--share-input-container {
          width: 100%;
          max-width: 526px;
          height: 62px;
          display: flex;
          padding: 0;

          & > div:first-child {
            width: 100%;

            button {
              min-width: 107px;
              margin-left: 0;
            }
          }
        }

        .section-bc-results-hero--share-splitter {
          width: 1px;
          height: 100%;
          border-left: 1px solid var(--dark);
          margin: 0 40px;
          opacity: 0.5;
        }

        .section-bc-results-hero--share-copy-btn {
          height: 48px;
          border: 1px solid var(--dark);
          background: transparent;
          font-weight: var(--font-weight-700);
          font-size: var(--font-size-16);
          line-height: var(--line-height-110);
          text-align: center;
          color: var(--dark);
          padding: 15px 24px;
          min-width: 121px;

          &:hover {
            background: #00000022;
          }
        }
      }
    }

    .section-bc-results-hero--third-section {
      display: flex;

      .section-bc-results-hero--third-left-side {
        .section-bc-results-hero--demo-container {
          max-width: 412px;
          padding: 40px 43px;
          background: white;
          box-shadow: 0px 3px 10px rgba(11, 26, 33, 0.08);

          .section-bc-results-hero--demo-title {
            font-weight: var(--font-weight-900);
            font-size: var(--font-size-28);
            line-height: var(--line-height-110);
            text-align: center;
            letter-spacing: var(--letter-spacing--0-03);
          }

          .section-bc-results-hero--demo-description {
            font-weight: var(--font-weight-500);
            font-size: var(--font-size-16);
            line-height: var(--line-height-150);
            text-align: center;
            margin-top: 31px;
            margin-bottom: 0;
          }

          .section-bc-results-hero--get-demo-btn {
            font-size: var(--font-size-20);
            width: 100%;
            height: 62px;
            margin-top: 46px;
          }
        }
      }

      .section-bc-results-hero--third-right-side {
        flex: auto;
        padding: 0 28px;

        .section-bc-results-hero--third-right-title {
          max-width: 613px;
          margin: 0 auto;

          .section-bc-results-hero--third-right-title-label {
            font-weight: var(--font-weight-700);
            font-size: var(--font-size-20);
            line-height: var(--line-height-120);
            text-align: center;
            letter-spacing: var(--letter-spacing--0-01);
          }
        }

        .section-bc-results-hero--third-value {
          display: flex;
          max-width: 600px;
          margin: 41px auto 0;
          justify-content: space-between;

          .section-bc-results-hero--third-value-text {
            padding-left: 50px;

            .section-bc-results-hero--third-value-title {
              font-weight: var(--font-weight-700);
              font-size: var(--font-size-20);
              line-height: var(--line-height-120);
              text-align: center;
              letter-spacing: var(--letter-spacing--0-01);
              margin-bottom: 14px;
            }

            .section-bc-results-hero--third-value-value {
              font-weight: var(--font-weight-900);
              font-size: var(--font-size-35);
              line-height: var(--line-height-110);
              text-align: center;
              letter-spacing: var(--letter-spacing--0-04);
              margin-bottom: 40px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .section-bc-results-hero--third-value-image {
            position: relative;

            .section-bc-results-hero--third-clock-image {
              max-width: 100%;
            }

            .section-bc-results-hero--third-clock-value-container {
              position: absolute;
              max-width: 120px;
              bottom: 0;
              right: 40px;

              .section-bc-results-hero--third-clock-value {
                font-weight: var(--font-weight-900);
                font-size: var(--font-size-35);
                line-height: var(--line-height-110);
                letter-spacing: var(--letter-spacing--0-04);
                margin: 0;
                text-align: right;
              }

              .section-bc-results-hero--third-clock-description {
                font-weight: var(--font-weight-500);
                font-size: var(--font-size-13);
                line-height: var(--line-height-150);
                margin: 0;
                text-align: right;
              }
            }
          }
        }
        .section-bc-results-hero--based-on-average {
          font-size: var(--font-size-13);
        }
      }
    }
  }

  @media (max-width: 991px) {
    .section-bc-results-hero--container {
      .section-bc-results-hero--first-section {
        .section-bc-results-hero--first-right-side {
          .section-bc-results-hero--metrics {
            .section-bc-results-hero--metrics-title {
              font-size: var(--font-size-18);
            }

            .section-bc-results-hero--metrics-retake {
              font-size: var(--font-size-18);

              i {
                margin-right: 5px;
                font-size: var(--font-size-13);
              }
            }
          }

          .section-bc-results-hero--metrics-card-container {
            .section-bc-results-hero--metrics-card {
              padding: 0 10px 0 20px;

              .section-bc-results-hero--metrics-card-title {
                font-size: var(--font-size-18);
                min-width: 96px;
                margin-right: 20px;
              }

              .section-bc-results-hero--metrics-card-value {
                font-size: var(--font-size-22);
              }
            }
          }
        }
      }

      .section-bc-results-hero--second-section {
        .section-bc-results-hero--share-title {
          text-align: left;
        }

        .section-bc-results-hero--share-container {
          .section-bc-results-hero--share-input-container {
            max-width: 100%;
          }
        }
      }

      .section-bc-results-hero--third-section {
        .section-bc-results-hero--third-left-side {
          .section-bc-results-hero--demo-container {
            max-width: 310px;
            padding: 25px;
          }
        }

        .section-bc-results-hero--third-right-side {
          padding-right: 0;

          .section-bc-results-hero--third-value {
            justify-content: space-around;

            .section-bc-results-hero--third-value-text {
              padding-left: 10px;
              padding-right: 20px;
              min-width: 215px;
            }

            .section-bc-results-hero--third-value-image {
              .section-bc-results-hero--third-clock-value-container {
                right: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-bc-results-hero--container {
      padding-left: 20px;
      padding-right: 20px;

      .section-bc-results-hero--first-section {
        flex-direction: column;

        .section-bc-results-hero--first-left-side {
          padding: 0;

          .section-bc-results-hero--title {
            font-weight: var(--font-weight-900);
            font-size: var(--font-size-20);
            line-height: var(--line-height-120);
          }

          .section-bc-results-hero--estimate {
            font-weight: var(--font-weight-900);
            font-size: var(--font-size-28);
            line-height: var(--line-height-110);
            letter-spacing: var(--letter-spacing--0-03);
            margin-top: 25px;
          }

          .section-bc-results-hero--graph-container {
            max-width: 100%;

            .section-bc-results-hero--graph-vertical-line {
              height: 43px;
            }

            .section-bc-results-hero--graph {
              height: 53px;

              &:before {
                height: calc(100% - 15px);
                top: 15px;
              }

              .section-bc-results-hero--graph-label {
                margin-top: -13px;
                width: 100%;
                margin-left: 0;

                p {
                  font-size: var(--font-size-13);
                }
              }

              .section-bc-results-hero--main-graph {
                top: 15px;
              }
            }

            .section-bc-results-hero--graph-label {
              width: 100%;
              margin-left: 0;

              p {
                font-size: var(--font-size-18);
              }
            }
          }
        }

        .section-bc-results-hero--first-right-side {
          margin-top: 55px;

          .section-bc-results-hero--metrics-card-container {
            .section-bc-results-hero--metrics-card {
              height: 90px;
              margin: 12px 0;
              padding: 0 55px;

              .section-bc-results-hero--metrics-card-title {
                margin-right: 32px;
                text-align: center;
              }
            }
          }
        }
      }

      .section-bc-results-hero--second-section {
        flex-direction: column;
        align-items: center;
        padding: 32px 0;

        .section-bc-results-hero--share-title {
          font-size: var(--font-size-20);
          letter-spacing: var(--letter-spacing--0-01);
          text-align: center;
          margin: 0;
        }

        .section-bc-results-hero--share-container {
          flex-direction: column;
          height: auto;
          margin-top: 10px;

          .section-bc-results-hero--share-splitter {
            width: 100%;
            height: 1px;
            border-left: none;
            border-top: 1px solid var(--dark);
            margin: 10px 0 12px;
          }

          .section-bc-results-hero--share-copy-btn {
            width: 100%;
          }
        }
      }

      .section-bc-results-hero--third-section {
        margin-top: 18px;
        flex-direction: column-reverse;

        .section-bc-results-hero--third-left-side {
          .section-bc-results-hero--demo-container {
            max-width: 412px;
            margin: 40px auto 0;
          }
        }

        .section-bc-results-hero--third-right-side {
          padding-left: 0;

          .section-bc-results-hero--third-right-title {
            .form-field--label-container {
              justify-content: center;
            }
          }

          .section-bc-results-hero--third-value {
            .section-bc-results-hero--third-value-text {
              .section-bc-results-hero--third-value-title {
                font-size: var(--font-size-20);
                line-height: var(--line-height-120);
              }

              .section-bc-results-hero--third-value-value {
                font-size: var(--font-size-35);
                line-height: var(--line-height-110);
              }
            }
          }
          .section-bc-results-hero--based-on-average {
            text-align: center;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .section-bc-results-hero--container {
      .section-bc-results-hero--first-section {
        .section-bc-results-hero--first-right-side
          .section-bc-results-hero--metrics-card-container
          .section-bc-results-hero--metrics-card {
          padding: 0 38px;
        }
      }

      .section-bc-results-hero--third-section {
        .section-bc-results-hero--third-right-side {
          .section-bc-results-hero--third-value {
            flex-direction: column-reverse;

            .section-bc-results-hero--third-value-text {
              margin-top: 50px;
            }

            .section-bc-results-hero--third-value-image {
              max-width: 247px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
`;

export const StepContainer = styled.div`
  max-width: 1160px;
  margin: 45px auto 65px;

  @media (max-width: 575px) {
    margin: 18px auto 40px;
  }
`;
