import React from 'react';
import FormControl from 'components/Core/FormControl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { stateList } from 'components/Form/utils/generalFormData';
import useIndustries from 'hooks/useIndustries';
import Select from 'components/Core/Select';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import useBusinessCalcImages from 'hooks/useBusinessCalcImages';
import { getFormValid } from 'utils/formUtils';
import { BusinessCalcFormContainer } from '../styles';
import { FormContainer } from '../../styles';

const BusinessCalcHomeForm = ({ width, height, formValues, onClickNext, ...otherProps }) => {
  const { industryList, otherIndustryList } = useIndustries();
  industryList.unshift({ label: 'Select an industry...', value: '' });
  otherIndustryList.unshift({ label: 'Select from other industries...', value: '' });
  const pageImages = useBusinessCalcImages();
  const manImage =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'Business Calculator Form Home')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  const formik = useFormik({
    initialValues: {
      industry: formValues.industry || '',
      otherIndustry: formValues.otherIndustry || '',
      state: formValues.state || '',
    },
    validationSchema: Yup.object().shape({
      industry: Yup.string().required('Industry is invalid'),
      otherIndustry: Yup.string().when('industry', {
        is: 'Other',
        then: Yup.string().required('Other Industry is invalid'),
      }),
      state: Yup.string().required('State is invalid'),
    }),
    onSubmit: (values) => {
      onClickNext(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const formValid = getFormValid({ ...formik });

  return (
    <FormContainer data-testid="form_container" {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container">
          <BusinessCalcFormContainer
            data-testid="business_calc_form_container"
            width={width}
            height={height}
            {...otherProps}
          >
            <div className="business-calc-home-form--container">
              <div className="business-calc-home-form--left-side">
                <div
                  data-position="Business Calculator Form"
                  className="business-calc-home-form--form"
                >
                  <FormLabelWithField
                    formControlProps={{ className: 'business-calc-home-form--form-item' }}
                    label="What industry do you do the most business in?"
                    isRequired
                  >
                    <Select
                      id="industry"
                      placeholder="Select Industry"
                      options={industryList}
                      formValid={formValid}
                      defaultValue={formik.values.industry}
                      value={formik.values.industry}
                      handleChange={onHandleChange}
                    />

                    {formik.values.industry === 'Other' && (
                      <Select
                        id="otherIndustry"
                        className="business-calc-home-form--other-industry"
                        placeholder="Select Other Industry"
                        options={otherIndustryList}
                        formValid={formValid}
                        defaultValue={formik.values.otherIndustry}
                        value={formik.values.otherIndustry}
                        handleChange={onHandleChange}
                      />
                    )}
                  </FormLabelWithField>

                  <FormLabelWithField
                    formControlProps={{ className: 'business-calc-home-form--form-item' }}
                    label="Where is your company located?"
                    isRequired
                  >
                    <Select
                      id="state"
                      placeholder="Select State"
                      options={stateList}
                      formValid={formValid}
                      handleChange={onHandleChange}
                      defaultValue={formik.values.state}
                      value={formik.values.state}
                    />
                  </FormLabelWithField>

                  <FormControl
                    fullWidth
                    data-position="Calculator - CTA"
                    className="business-calc-home-form--form-item"
                  >
                    <Button
                      id="submit"
                      className="business-calc-home-form--submit-button"
                      type="primary"
                      width="100%"
                      shadow={false}
                      onClick={() => formik.submitForm()}
                    >
                      Get Started
                    </Button>
                  </FormControl>

                  <div className="business-calc-home-form--footer-description">
                    This interactive calculator is made available to you as a self-help tool for
                    your independent use and is not intended to provide financial advice.
                  </div>
                </div>
              </div>
              <div className="business-calc-home-form--right-side">
                <img src={manImage} className="business-calc-home-form--man-image" alt="" />
              </div>
            </div>
          </BusinessCalcFormContainer>
        </div>
      </div>
    </FormContainer>
  );
};

const BCHomeForm = BusinessCalcHomeForm;
export default BCHomeForm;
